import { css } from '@emotion/react'

export const pdfBtn01 = css`
  .news-list {
    font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ',
      Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
    .news-list__ttl--sm {
      font-size: 14px;
      font-family: 'Rajdhani';
      margin-bottom: 8px;
    }
    .news-list__txt--lg {
      font-size: 14px;
      max-width: 600px;
      .news-list__ico-pdf {
        line-height: 1;
      }
    }
  }
`
