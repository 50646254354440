import React from 'react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { useLoadLocalScript } from '../../../components/hooks/useLoadLocalScript'
import { year } from '../../../components/styles/year'
import { pdfBtn01 } from '../../../components/styles/pdfBtn01'
import {
  selectMenuStyle,
  styleIRNews,
} from '../../../components/styles/styleIRNews'
import { SEOIR } from '../../../components/data/SEO'
import { ENGLISH_TITLE } from '../../../components/data/English'

const Page = () => {
  // ローカルのscriptタグを埋めたい場合
  useLoadLocalScript('/common/js/ir/XjStorageLoaderlibrary03.js')

  return (
    <LayoutIR
      heading="有価証券報告書等"
      headingEnglish={ENGLISH_TITLE.AnnualReports}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: 'IRライブラリ', url: '/ir/library' },
        { title: '有価証券報告書等', url: '/' },
      ]}
    >
      <div className="pc:pt-12 pc:px-8 tab:pr-4 pc:pr-8 pb-12 bg-white">
        <div css={selectMenuStyle}>
          <form
            action="#"
            method="post"
            name="form"
            id="form"
            className="mb-12"
          >
            <select
              css={year}
              name="year"
              id="xj-select-year_s"
              className="contact-select ir-select"
            ></select>
          </form>
          <div css={pdfBtn01}>
            <div css={styleIRNews} id="xj-mainlist"></div>
          </div>
        </div>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEOIR title="有価証券報告書等" url="/ir/library/securities" />
)
